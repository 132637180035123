import { getBrandConfig } from 'config/brands';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthContext } from 'context/AuthContextProvider';

const Header = () => {
  const user = useAuthContext();
  const router = useRouter();

  return (
    <div className="fixed right-0 left-0 top-0 z-50 flex h-16 items-center justify-between bg-red-500 p-4">
      <div
        onClick={() => {
          router.push('/my-bookings');
        }}
        className="grid cursor-pointer grid-flow-col place-items-center gap-4 text-sm text-white"
      >
        <FontAwesomeIcon size="2x" icon={faUserCircle} />
        <div>
          <div>Signed in as</div>
          <div className="font-semibold max-w-[200px] overflow-ellipsis overflow-hidden">
            {user?.phoneNumber || user?.email}
          </div>
        </div>
      </div>
      <Image
        priority
        src={`/${getBrandConfig().logo}`}
        alt={getBrandConfig().name}
        width={300 / 3.5}
        height={108 / 3.5}
        layout="fixed"
        className="bg-red-500"
      />
    </div>
  );
};

export default Header;
