import Header from 'components/Header';
import Navigation from 'components/Navigation';

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="grid py-20">
      <Header />
      <div className="mx-auto flex flex-col w-full max-w-4xl justify-center p-3 lg:p-8">
        {children}
      </div>
      <Navigation />
    </div>
  );
};

export default Layout;
