import Link from 'next/link';
import { useState } from 'react';
import {
  faBook,
  faCalendarPlus,
  faQuestionCircle,
  faSignOut
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader
} from '@material-tailwind/react';
import { useAuthContext } from 'context/AuthContextProvider';
import { signOut } from 'lib/firebase';

const navButtonClasses =
  'grid cursor-pointer place-items-center gap-2 whitespace-nowrap rounded text-center';

const Navigation = () => {
  const user = useAuthContext();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);

  return (
    <div
      className={`fixed bottom-0 left-0 right-0 grid place-items-center bg-gray-900 p-4 z-50`}
    >
      <nav className="max-w-lg w-full grid auto-cols-fr grid-flow-col gap-2 text-xs text-white">
        {user?.isAdmin ? (
          <div>
            <Link href="/admin/bookings">
              <div className={navButtonClasses}>
                <FontAwesomeIcon size="xl" icon={faBook} />
                <span>All bookings</span>
              </div>
            </Link>
          </div>
        ) : (
          <>
            <Link href="/my-bookings">
              <div className={navButtonClasses}>
                <FontAwesomeIcon size="xl" icon={faBook} />
                <span>Bookings</span>
              </div>
            </Link>
            <Link href="/">
              <div className={navButtonClasses}>
                <FontAwesomeIcon size="xl" icon={faCalendarPlus} />
                <span>New</span>
              </div>
            </Link>
            <Link href="/help">
              <div className={navButtonClasses}>
                <FontAwesomeIcon size="xl" icon={faQuestionCircle} />
                <span>Help</span>
              </div>
            </Link>
          </>
        )}
        <button className={navButtonClasses} type="button" onClick={handleOpen}>
          <FontAwesomeIcon size="xl" icon={faSignOut} />
          <span>Sign out</span>
        </button>
      </nav>
      <Dialog size="xl" open={open} handler={handleOpen}>
        <DialogHeader>Sign out</DialogHeader>
        <DialogBody divider>Are you sure you want to sign out?</DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="gray"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>No</span>
          </Button>
          <Button onClick={() => signOut()} color="red">
            <span>Yes, sign out</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default Navigation;
