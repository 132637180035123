import { useRouter } from 'next/router';
import { useState } from 'react';
import { Button } from '@material-tailwind/react';
import {
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { PaymentIntent, StripeError } from '@stripe/stripe-js';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { StripeProps } from 'components/Stripe/Stripe';
import { useAppContext } from 'context/AppContext';
import { useAuthContext } from 'context/AuthContextProvider';
import { useServiceContext } from 'context/ServicesCartContextProvider';

interface PaymentIntentResponse {
  client_secret: string;
  status: PaymentIntent['status'];
  paymentIntentId: string;
  error: PaymentIntent['last_payment_error'];
  bookingId?: string;
}

const CheckoutForm = ({ onSubmit, onComplete }: StripeProps) => {
  const user = useAuthContext();
  const router = useRouter();
  const { setIsPaymentProcessing, currentBookingDetails } = useAppContext();
  const { cart } = useServiceContext();
  const stripe = useStripe();
  const elements = useElements();
  const bookingId = router.query.id as string;

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleError = (error: StripeError) => {
    setLoading(false);
    setErrorMessage(error?.message ?? '');
    setIsPaymentProcessing(false);
  };

  const handleServerResponse = async (response: PaymentIntentResponse) => {
    if (response.error) {
      // Show error from server on payment form
      setLoading(false);
      setErrorMessage(response.error.message ?? '');
      setIsPaymentProcessing(false);
      return;
    }

    if (response.status === 'requires_action' && stripe) {
      // Use Stripe.js to handle the required next action
      const { error, paymentIntent } = await stripe.handleNextAction({
        clientSecret: response.client_secret
      });

      if (error) {
        // Show error from Stripe.js in payment form
        setErrorMessage(error.message ?? '');
        setLoading(false);
        setIsPaymentProcessing(false);
        return;
      }

      return paymentIntent?.status === 'succeeded';
    }
  };

  const handleSubmit = async () => {
    const createPaymentIntent = async (
      paymentMethodId: string,
      paymentIntentId?: string
    ) => {
      const res = await fetch('/api/services/create-payment-intent', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          paymentMethodId,
          paymentIntentId,
          items: cart.map((product) => ({
            id: product.id,
            quantity: product.quantity
          })),
          bookingId,
          ...(onSubmit && {
            eventParams: await onSubmit()
          })
        })
      });

      const data = (await res.json()) as PaymentIntentResponse;
      return data;
    };
    if (!stripe || !elements) return;
    setLoading(true);
    setIsPaymentProcessing(true);
    setErrorMessage('');

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    // Create the PaymentMethod using the details collected by the Payment Element
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: {
          name: `${currentBookingDetails.firstName} ${currentBookingDetails.lastName}`,
          phone: currentBookingDetails?.phoneNumber || user?.phoneNumber || '',
          email: user?.email || currentBookingDetails?.email || ''
        }
      }
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // creating the PaymentMethod. Show the error to your customer (for example, payment details incomplete)
      handleError(error);
      return;
    }

    // Create the PaymentIntent
    let data = await createPaymentIntent(paymentMethod?.id ?? '');

    const isSuccess = await handleServerResponse(data);
    if (data.status === 'requires_action' && isSuccess) {
      data = await createPaymentIntent(
        paymentMethod?.id ?? '',
        data.paymentIntentId
      );
    }
    setIsPaymentProcessing(false);
    if (data.bookingId) onComplete?.(data.bookingId);
  };

  return (
    <div className="grid">
      <PaymentElement id="payment-element" options={{ layout: 'tabs' }} />
      {loading && (
        <div className="py-4">
          <div className="text-center">
            We are processing your payment, one moment please.
          </div>
          <LoadingSpinner inline />
        </div>
      )}
      {errorMessage && (
        <div className="bg-red-500 text-white p-2 rounded mt-2">
          {errorMessage}
        </div>
      )}
      {!loading && (
        <Button
          onClick={handleSubmit}
          disabled={loading}
          color="blue"
          className="mx-auto mt-2"
          type="submit"
        >
          Pay and confirm
        </Button>
      )}
    </div>
  );
};

export default CheckoutForm;
