/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import {
  CartItem,
  useServiceContext
} from 'context/ServicesCartContextProvider';
import ServiceProductCard from './ServiceItem/ServiceProductCard';
import ServiceItemsTotal from './ServiceItemsTotal/ServiceItemsTotal';

interface Props {
  existingServiceIds?: string[];
  filters?: string[];
}

const Services = ({ existingServiceIds = [], filters = [] }: Props) => {
  const { cart, setCart } = useServiceContext();

  const getServices = (): Promise<CartItem[]> => {
    return fetch('/api/services/get-products', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ filters })
    }).then((r) => r.json());
  };

  const { data: services, isLoading } = useQuery(
    ['getServices', filters],
    () => getServices(),
    {
      cacheTime: 300000,
      staleTime: 300000
    }
  );

  const filteredServices = (services || []).filter(
    (item) => !existingServiceIds.includes(item.id)
  );

  const toggleService = (item: CartItem) => {
    cart.map((product) => product.id).includes(item.id)
      ? setCart(
          cart.filter((service) => {
            return service.id !== item.id;
          })
        )
      : setCart([...cart, item]);
  };

  if (isLoading)
    return (
      <div className="grid place-items-center p-4 overflow-hidden">
        <LoadingSpinner inline />
      </div>
    );

  if (filteredServices.length === 0) {
    return <div>No services currently available</div>;
  }

  if (services) {
    return (
      <div className="grid w-full animate-fade-in gap-4">
        <div className="grid w-full gap-4 rounded">
          {filteredServices.map((item) => (
            <ServiceProductCard
              onClick={() => toggleService(item)}
              isInCart={!!cart.find((cartItem) => item.id === cartItem.id)}
              price={item.price}
              cardId={item.id}
              key={item.id}
              name={item.label}
              cardImage={item.imageURL}
              desc={item.description}
            />
          ))}
        </div>
        <ServiceItemsTotal />
      </div>
    );
  }

  return null;
};

export default Services;
