/* eslint-disable @next/next/no-img-element */
import React, { useState } from 'react';
import { faCheck, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, Typography } from '@material-tailwind/react';

interface ServiceCard {
  cardId: string;
  cardImage: string;
  price: number;
  name: string;
  desc: string;
  isInCart: boolean;
  onClick: () => void;
}

const ServiceProductCard = ({
  cardImage,
  desc,
  isInCart,
  name,
  onClick,
  price
}: ServiceCard) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!isExpanded) {
    return (
      <Card
        className={`cursor-pointer w-full border ${
          isInCart ? 'border-red-500' : ''
        }`}
        onClick={() => {
          setIsExpanded(true);
        }}
      >
        <CardBody className="text-ellipsis flex p-0 text-left relative">
          <div className="p-3 w-full z-10">
            <Typography className="font-bold overflow-hidden text-ellipsis block text-inherit text-black">
              {name}
            </Typography>
            <div className="flex items-end justify-between gap-2">
              <small className="underline">Details</small>
              <div className="flex items-center gap-2">
                <Typography
                  className="font-semibold text-gray-900"
                  variant="small"
                >
                  €{price}
                </Typography>
                <Button
                  size="sm"
                  variant={isInCart ? 'outlined' : 'filled'}
                  color="red"
                  className="font-medium cursor-pointer select-none border border-red-500"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick();
                  }}
                >
                  {isInCart ? (
                    <FontAwesomeIcon size="lg" icon={faTrash} />
                  ) : (
                    <FontAwesomeIcon size="lg" icon={faPlus} />
                  )}
                </Button>
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url('${cardImage}')`
            }}
            className="absolute inset-0 bg-cover opacity-10 rounded-lg"
          >
            {isInCart && (
              <div className="content h-full flex  justify-center items-center bg-blue-600/30 backdrop-brightness-75">
                <FontAwesomeIcon size="2x" icon={faCheck} inverse />
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card
      className={`cursor-pointer w-full border ${
        isInCart ? 'border-red-500' : ''
      }`}
      onClick={() => setIsExpanded(false)}
    >
      <CardBody className="text-ellipsis flex p-0 text-left relative">
        <div className="px-4 py-6 w-full z-10 grid">
          <div>
            <Typography className="block text-xl font-bold text-black">
              {name}
            </Typography>
            <Typography className="mt-1 mb-8 font-medium text-gray-900">
              {desc}
            </Typography>
          </div>
          <div className="flex items-center gap-4 ">
            <Typography className="font-semibold text-gray-900">
              €{price}
            </Typography>
            <Button
              size="sm"
              variant={isInCart ? 'outlined' : 'filled'}
              color="red"
              className="font-medium cursor-pointer select-none	h-10 ml-auto"
              onClick={(e) => {
                e.stopPropagation();
                onClick();
              }}
            >
              <FontAwesomeIcon
                size="lg"
                icon={isInCart ? faTrash : faPlus}
                className="mr-2"
              />
              {isInCart ? 'Remove from cart' : 'Add to cart'}
            </Button>
          </div>
        </div>
        <div
          style={{
            backgroundImage: `url('${cardImage}')`
          }}
          className="absolute inset-0 bg-cover opacity-10 rounded-lg"
        >
          {isInCart && (
            <div className="content h-full flex justify-center items-center bg-blue-600/30 backdrop-brightness-75">
              <FontAwesomeIcon size="2x" icon={faCheck} inverse />
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default ServiceProductCard;
