const LoadingSpinner = ({
  message,
  inline = false
}: {
  message?: string;
  inline?: boolean;
}) => {
  return (
    <div
      className="inset-0 grid place-items-center bg-white"
      style={{
        position: inline ? 'relative' : 'fixed',
        inset: inline ? 0 : undefined,
        zIndex: inline ? 0 : 51
      }}
    >
      <div className="grid place-items-center">
        <div className="lds-ellipsis">
          <div className="bg-red-500"></div>
          <div className="bg-red-500"></div>
          <div className="bg-red-500"></div>
          <div className="bg-red-500"></div>
        </div>
        <div>{message}</div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
