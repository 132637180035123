import Compressor from 'compressorjs';
import { differenceInDays } from 'date-fns';
import {
  format,
  formatInTimeZone,
  utcToZonedTime,
  zonedTimeToUtc
} from 'date-fns-tz';
import Stripe from 'stripe';
import { TIME_ZONE, calendarConfig } from './calendar-config';
import { LOCALE, priceMap } from './constants';
import { OrderItem, ParkingType, PaymentData } from './types';

type PricingShortTerm = {
  basePrice: number;
  perDayPrice: number;
  dropOffFeePrice: number;
};

export const toDateTimeZoned = (date: string | number | Date) =>
  new Date(formatInTimeZone(date, TIME_ZONE, 'yyyy-MM-dd HH:mm:ss'));

export const toISOStringTimeZoned = (date: string | number | Date) => {
  return zonedTimeToUtc(date, TIME_ZONE).toISOString();
};

export const formatDate = (date: string) =>
  new Date(date).toLocaleString(LOCALE, {
    timeZone: TIME_ZONE,
    timeStyle: 'short',
    dateStyle: 'short'
  });

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const isUncomfortableHours = (dateInUTC: string, timeZone: string) => {
  const zonedDate = utcToZonedTime(dateInUTC, timeZone);
  const hour = +format(zonedDate, 'HH', { timeZone });

  return hour >= 0 && hour < 7;
};

export function formatDateForInput(date: string) {
  if (!date) return '';
  return new Date(date)
    .toLocaleDateString(LOCALE, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: TIME_ZONE
    })
    .split(' ')
    .join('T');
}

export const calculatePrice = ({
  basePrice,
  perDayPrice,
  dropOffFeePrice,
  startDate,
  endDate
}: PricingShortTerm & {
  startDate: string | undefined;
  endDate: string | undefined;
}) => {
  if (!startDate || !endDate)
    return {
      basePrice: 0,
      perDayPrice: 0,
      additionalDays: 0,
      totalPrice: 0
    };

  const hasExtraFee = isUncomfortableHours(startDate, calendarConfig.timeZone);
  const additionalDays = Math.max(
    differenceInDays(new Date(endDate), new Date(startDate)) - 7,
    0
  );

  return {
    basePrice: basePrice / 100,
    perDayPrice: perDayPrice / 100,
    additionalDays,
    totalPrice:
      (basePrice +
        (hasExtraFee ? dropOffFeePrice : 0) +
        additionalDays * perDayPrice) /
      100
  };
};

export function createStripeInstance() {
  return new Stripe(process.env.STRIPE_SECRET_KEY!, {
    apiVersion: '2022-11-15'
  });
}

export async function getPaymentIntentWithProducts(
  paymentIntentIds: string[]
): Promise<PaymentData> {
  if (paymentIntentIds.length === 0)
    return { paymentIntents: [], products: [], charges: [] };

  const stripe = createStripeInstance();

  const paymentIntents = await Promise.all(
    paymentIntentIds.map((id) => stripe.paymentIntents.retrieve(id))
  );

  const charges = await Promise.all(
    paymentIntents
      .map((pi) => pi.latest_charge)
      .filter(Boolean)
      .map((latestCharge) => stripe.charges.retrieve(latestCharge as string))
  );

  const parsedProductsList = paymentIntents.map((pi) =>
    JSON.parse(pi.metadata.items)
  );

  const products =
    parsedProductsList.length === 0
      ? []
      : await Promise.all(
          parsedProductsList.map((products) =>
            stripe.products.list({
              ids: products.map((p: OrderItem) => p.id),
              expand: ['data.default_price']
            })
          )
        );

  return {
    paymentIntents,
    products,
    charges
  };
}

export const getShortTermCart = (
  startDate: Date,
  endDate: Date,
  parkingType: ParkingType,
  pricing: PricingShortTerm
) => {
  const { additionalDays, basePrice, perDayPrice } = calculatePrice({
    ...pricing,
    startDate: startDate?.toISOString(),
    endDate: endDate?.toISOString()
  });

  const hasExtraFee = isUncomfortableHours(
    startDate?.toISOString(),
    calendarConfig.timeZone
  );

  const result = [
    {
      id: priceMap[parkingType!].basePriceId as string,
      description: '',
      imageURL: '',
      label: 'Short term parking, 1 week',
      price: basePrice,
      quantity: 1
    }
  ];

  if (hasExtraFee) {
    result.push({
      id: priceMap['dropOffFee'],
      description: '',
      imageURL: '',
      label: 'Drop off fee (00:00 - 07:00)',
      price: pricing.dropOffFeePrice / 100,
      quantity: 1
    });
  }

  if (additionalDays > 0) {
    result.push({
      id: priceMap[parkingType!].perDayPriceId,
      description: '',
      imageURL: '',
      label: `Short term parking, ${
        pricing.perDayPrice / 100
      }€ per day (x${additionalDays})`,
      price: perDayPrice,
      quantity: additionalDays
    });
  }

  return result;
};

export const compressImageAsync = (file: File): Promise<File | Blob> => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.8,
      maxWidth: 1200,
      maxHeight: 1200,
      success: async (result) => {
        resolve(result);
      },
      error(err) {
        reject(err.message);
      }
    });
  });
};
