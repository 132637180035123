import { EventInput } from 'pages/api/calendar/utils';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import CheckoutForm from 'components/CheckoutForm/CheckoutForm';
import ServiceItemsTotal from 'components/Service/ServiceItemsTotal/ServiceItemsTotal';
import { useServiceContext } from 'context/ServicesCartContextProvider';
import { CURRENCY } from 'lib/constants';

const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY!
);

const appearance = {
  theme: 'stripe' as const
};

export interface StripeProps {
  onSubmit?: () => Promise<EventInput>;
  onComplete?: (bookingId: string) => void;
}

export default function Stripe({ onSubmit, onComplete }: StripeProps) {
  const { cart } = useServiceContext();

  const options: StripeElementsOptions = {
    mode: 'payment',
    payment_method_types: ['card'],
    amount: Math.round(
      cart.reduce(
        (total, product) => (total += product.price * product.quantity),
        0
      ) * 100
    ),
    currency: CURRENCY,
    paymentMethodCreation: 'manual',
    appearance
  };

  if (cart.length === 0) return null;

  return (
    <div>
      <div className="text-xl">Services</div>
      <div className="grid mb-8">
        <ServiceItemsTotal />
      </div>
      <Elements options={options} stripe={stripePromise}>
        <CheckoutForm {...{ onSubmit, onComplete }} />
      </Elements>
    </div>
  );
}
